import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import moment from 'moment-timezone';
import { getTimezoneList, setPageTitle } from '../../utils/functions';
import SingleForm from '../core/input/Form/SingleForm';
import CourseCard from './CourseCard';
import HelpTag from '../core/display/HelpTag';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import Button from '../core/button/Button/Button';
import Calendar, { CalendarRange } from '../core/display/Calendar/Calendar';
import { openModal, useModalContext } from '../../contexts/ModalContext';
const { Row, Col, FormGroup, InputGroup } = SingleForm;

interface Props {
  initCourseName?: string;
  initDiscipline?: string;
  initUniversity?: string;
  initTimeZone?: string;
  initLabel?: string;
  initStudentPurchaseEnabled?: 'true' | 'false';
  initAsyncEnabled?: 'true' | 'false';
  initAssignmentPublicationTime?: string;
  initAsyncAssignmentEndDeadline?: string;
  onSubmit?: (arg0: FormData, callback: () => void) => void;
  title: string;
  type: 'NEW' | 'EDIT' | 'COPY';
}

function CourseForm({
  initCourseName = '',
  initDiscipline = '',
  initUniversity = '',
  initTimeZone,
  initLabel = '',
  initStudentPurchaseEnabled = 'false',
  initAsyncEnabled = 'false',
  onSubmit = () => undefined,
  title,
  type,
  initAssignmentPublicationTime = '',
  initAsyncAssignmentEndDeadline = '',
}: Props): JSX.Element {
  useEffect(() => setPageTitle(`${type.charAt(0) + type.slice(1).toLowerCase()} Course`), [type]);

  const user = useSelector((state: RootState) => state.user);
  const { modalDispatch } = useModalContext();

  const date = new Date();
  const month = date.getMonth();
  const currYear = date.getUTCFullYear();

  let season: 'Spring' | 'Summer' | 'Fall' | 'Winter' | 'Full Year' = 'Spring';
  if (month >= 11 && month <= 1) season = 'Winter';
  else if (month >= 2 && month <= 4) season = 'Spring';
  else if (month >= 5 && month <= 7) season = 'Summer';
  else if (month >= 8 && month <= 10) season = 'Fall';

  const years: number[] = [];
  for (let i = currYear; i <= currYear + 3; i++) years.push(i);

  const [name, setName] = useState<string>(initCourseName);
  const [discipline, setDiscipline] = useState<string>(initDiscipline);
  const [institution, setInstitution] = useState<string>(initUniversity);
  const [semester, setSemester] = useState<'Spring' | 'Summer' | 'Fall' | 'Winter' | 'Full Year'>(season);
  const [year, setYear] = useState<string>(currYear + '');
  const [label, setLabel] = useState<string>(initLabel);
  const [studentPurchaseEnabled, setStudentPurchaseEnabled] = useState<'true' | 'false'>(initStudentPurchaseEnabled);
  const [asyncEnabled, setAsyncEnabled] = useState<'true' | 'false'>(initAsyncEnabled);
  const [assignmentPublicationTime, setAssignmentPublicationTime] = useState<string>(initAssignmentPublicationTime);
  const [asyncAssignmentEndDeadline, setAsyncAssignmentEndDeadline] = useState<string>(initAsyncAssignmentEndDeadline);

  const [selectingIndex, setSelectingIndex] = useState<number | undefined>(undefined);

  const timezone = useRef<string>(initTimeZone ? initTimeZone : moment.tz.guess());
  const timezoneList = useRef<string[]>(getTimezoneList());

  const handleDateSelect = (index: number, date: string) => {
    if (index === 0) {
      setAssignmentPublicationTime(date);
    } else if (index === 1) {
      setAsyncAssignmentEndDeadline(date);
    }
  };

  const onInvalid = useCallback(
    (heading: string, label: string) =>
      modalDispatch(
        openModal({
          heading,
          label,
          cancelHide: true,
        }),
      ),
    [modalDispatch],
  );

  const validateNewDate = (index: number, date: string): boolean => {
    const currMoment = moment();
    if (currMoment.isAfter(date)) {
      onInvalid('Invalid Date', 'Dates must be in the future');
      return false;
    }

    const selectedMoment = moment(date, 'YYYY-MM-DD');
    if (selectedMoment.diff(currMoment, 'days') < index) {
      onInvalid(
        'Date is Too Soon',
        `The date you've chosen is too soon to give the assignment sufficient time. Please select a date further in the future.`,
      );
      return false;
    }

    return true;
  };

  const ranges: CalendarRange[] = useMemo(() => {
    if (asyncEnabled === 'true' && assignmentPublicationTime && asyncAssignmentEndDeadline) {
      const formattedPublicationDate = moment(assignmentPublicationTime).format('YYYY-MM-DD');
      const formattedDeadlineDate = moment(asyncAssignmentEndDeadline).format('YYYY-MM-DD');
      return [
        {
          color: '#7878F1',
          low: formattedPublicationDate,
          high: formattedDeadlineDate,
          label: 'Assignment Range',
        },
      ];
    }
    return [];
  }, [asyncEnabled, assignmentPublicationTime, asyncAssignmentEndDeadline]);

  return (
    <SingleForm
      className="course-form"
      title={title}
      submitText={type === 'EDIT' ? 'Save Changes' : 'Create'}
      onSubmit={onSubmit}
      backButton
      backUrl="/course/dashboard"
    >
      <Row>
        <Col>
          <FormGroup>
            <InputGroup iconCode="class" flexDirection="column">
              <label className="sr-only" htmlFor="course-name">
                Course Name
              </label>
              <input
                name="courseName"
                id="course-name"
                type="text"
                placeholder="Course Name"
                required={true}
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
              <label className="sr-only" htmlFor="course-discipline">
                Discipline
              </label>
              <input
                name="discipline"
                id="course-discipline"
                type="text"
                placeholder="Discipline"
                required={true}
                value={discipline}
                onChange={(e) => {
                  setDiscipline(e.target.value);
                }}
              />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <InputGroup iconCode="place" flexDirection="column">
              <label className="sr-only" htmlFor="course-institution">
                Institution
              </label>
              <input
                name="university"
                id="course-institution"
                type="text"
                placeholder="Institution"
                required={true}
                value={institution}
                onChange={(e) => {
                  setInstitution(e.target.value);
                }}
              />
            </InputGroup>
            <InputGroup iconCode="event">
              <label className="sr-only" htmlFor="course-semester">
                Semester
              </label>
              <select
                name="semester"
                id="course-semester"
                required={true}
                value={semester}
                onChange={(e) => {
                  setSemester(e.target.value as 'Spring' | 'Summer' | 'Fall' | 'Winter' | 'Full Year');
                }}
              >
                <option>Spring</option>
                <option>Summer</option>
                <option>Fall</option>
                <option>Winter</option>
                <option>Full Year</option>
              </select>
              <label className="sr-only" htmlFor="course-year">
                Year
              </label>
              <select
                name="year"
                id="course-year"
                required={true}
                value={year}
                onChange={(e) => {
                  setYear(e.target.value);
                }}
              >
                {years.map((yr) => (
                  <option key={yr}>{yr}</option>
                ))}
              </select>
            </InputGroup>
            <InputGroup iconCode="language">
              <label className="sr-only" htmlFor="course-timezone">
                Timezone
              </label>
              <select name="timeZone" id="course-timezone" defaultValue={timezone.current} required={true}>
                {timezoneList.current.map((tz) => (
                  <option key={tz}>{tz}</option>
                ))}
              </select>
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <InputGroup iconCode="label">
              <label className="sr-only" htmlFor="course-label">
                Label (Optional)
              </label>
              <input
                name="label"
                id="course-label"
                type="text"
                placeholder="Label (Optional)"
                value={label}
                onChange={(e) => {
                  setLabel(e.target.value);
                }}
              />
              <HelpTag>
                <b>Labels</b> can be used to identify the course. <i>(i.e. section, schedule...)</i>
              </HelpTag>
            </InputGroup>
          </FormGroup>
          {user.purchasingEnabled ? (
            <FormGroup>
              <InputGroup iconCode="attach_money">
                <label className="sr-only" htmlFor="course-purchase">
                  Purchase Options
                </label>
                <select
                  name="studentPurchaseEnabled"
                  id="course-purchase"
                  required={true}
                  value={studentPurchaseEnabled}
                  onChange={(e) => {
                    setStudentPurchaseEnabled(e.target.value as 'true' | 'false');
                  }}
                  disabled={type === 'EDIT'}
                >
                  <option value="false">Enrollment Seats Purchased By Instructor</option>
                  <option value="true">Enrollment Seats Purchased By Students</option>
                </select>
              </InputGroup>
            </FormGroup>
          ) : null}

          <FormGroup>
            <InputGroup iconCode="schedule">
              <label className="sr-only" htmlFor="course-sync">
                Synchronization Options
              </label>
              <select
                name="asyncEnabled"
                id="course-sync"
                required={true}
                value={asyncEnabled}
                onChange={(e) => {
                  setAsyncEnabled(e.target.value as 'true' | 'false');
                }}
                disabled={type === 'EDIT'}
              >
                <option value="false">Sync Mode</option>
                <option value="true">Async Mode</option>
              </select>
              <HelpTag>
                <p>
                  <b>Sync Mode:</b> Multiple set deadlines and grading
                </p>
                <p>
                  <b>Async Mode:</b> Final deadline only and competency-based grading
                </p>
              </HelpTag>
            </InputGroup>
            {asyncEnabled === 'true' ? (
              <InputGroup iconCode="timer">
                <div>
                  <h3 id="publicationDateLabel" style={{ display: 'inline-block' }}>
                    Assignment Publication Date:
                  </h3>
                  <Button
                    variant="alt rad low"
                    id="course-publication-date"
                    className="assignmentPublicationTime"
                    type="button"
                    disabled={type === 'EDIT'}
                    onClick={() => setSelectingIndex(0)}
                    ariaLabel={`Publication date ${moment(assignmentPublicationTime).format(
                      'MMMM DD YYYY',
                    )}; To change, select button and choose a date on the calendar`}
                  >
                    {moment(assignmentPublicationTime).format('MM/DD/YYYY')}
                  </Button>
                  <input type="hidden" name="assignmentPublicationTime" value={assignmentPublicationTime} />
                </div>
              </InputGroup>
            ) : null}

            {asyncEnabled === 'true' ? (
              <InputGroup iconCode="timer">
                <div>
                  <h3 id="deadlineDateLabel" style={{ display: 'inline-block' }}>
                    Assignment Deadline:
                  </h3>
                  <Button
                    variant="alt rad low"
                    type="button"
                    id="course-assignment-deadline"
                    className="asyncAssignmentEndDeadline"
                    disabled={type === 'EDIT'}
                    onClick={() => setSelectingIndex(1)}
                    ariaLabel={`Deadline date ${moment(asyncAssignmentEndDeadline).format(
                      'MMMM DD YYYY',
                    )}; To change, select button and choose a date on the calendar`}
                  >
                    {moment(asyncAssignmentEndDeadline).format('MM/DD/YYYY')}
                  </Button>
                  <input type="hidden" name="asyncAssignmentEndDeadline" value={asyncAssignmentEndDeadline} />
                </div>
              </InputGroup>
            ) : null}
          </FormGroup>
        </Col>
        <Col className="justify-center">
          <figure className="course-card-preview">
            <CourseCard
              name={name === '' ? 'Course Name' : name}
              discipline={discipline === '' ? 'Discipline' : discipline}
              semester={semester}
              year={parseInt(year)}
              label={label === '' ? 'Label' : label}
              readOnly={true}
            />
            <figcaption>Preview</figcaption>
          </figure>
          {asyncEnabled === 'true' ? (
            <Calendar
              className={selectingIndex !== undefined ? 'focus-pulse' : undefined}
              focusTrap={selectingIndex !== undefined}
              onSelect={(date) => {
                if (selectingIndex !== undefined) {
                  if (validateNewDate(selectingIndex, date)) {
                    handleDateSelect(selectingIndex, date);
                  }
                  setSelectingIndex(undefined);
                }
              }}
              onOutsideClick={() => setSelectingIndex(undefined)}
              ranges={ranges}
            />
          ) : null}
        </Col>
      </Row>
    </SingleForm>
  );
}

export default CourseForm;
