import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { countWords, focusFirstElement, formDataToObjectParsed } from '../../../../utils/functions';
import {
  PinData,
  activatePinning,
  deactivatePinning,
  renderPinDropLabel,
  usePinDropContext,
} from '../../../../contexts/PinDropContext';
import Button from '../../button/Button/Button';
import Icon from '../../display/Icon';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { updateCommentTable } from '../../../../actions';
import { openModal, useModalContext } from '../../../../contexts/ModalContext';

interface Props {
  defaultValue?: string;
  commentId: string;
  commentNumber?: number;
  labelText: string;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onInvalid?: (e: React.InvalidEvent<HTMLTextAreaElement>) => void;
  onPinDelete?: () => void;
  pinDrop?: PinData | null;
  placeholder?: string;
  required?: boolean;
  review?: boolean;
  minimumWordCount?:number;
}

function TextBox({
  defaultValue,
  commentId,
  commentNumber = 0,
  labelText,
  onBlur = () => undefined,
  onChange = () => undefined,
  onInvalid = () => undefined,
  onPinDelete = () => undefined,
  pinDrop,
  placeholder,
  required,
  review = false,
  minimumWordCount,
}: Props): JSX.Element {
  const textboxEl = useRef<HTMLDivElement>(null);

  const id = useMemo(() => `peer-textbox-${commentId}-${commentNumber}`, [commentId, commentNumber]);

  // PinDrop Context:
  const { pinDropContextState, pinDropDispatch } = usePinDropContext();
  const [value, setValue] = useState(defaultValue ?? '');

  // Redux:
  const dispatch = useDispatch();
  const commentTable = useSelector((state: RootState) => state.commentTable);

  const { modalDispatch } = useModalContext();

  const wordCount = useMemo(() => countWords(value), [value]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setValue(e.target.value);
      onChange(e);
    },
    [onChange],
  );

  const handleBlur = useCallback((e: React.FocusEvent<HTMLTextAreaElement>) => onBlur(e), [onBlur]);

  const handleInvalid = useCallback(
    (e: React.InvalidEvent<HTMLTextAreaElement>) => {
      onInvalid(e);
      e.target.style.borderColor = 'red';
    },
    [onInvalid],
  );

  useEffect(() => {
    requestAnimationFrame(() => {
      const labelHeight = document.getElementById(`${id}-label`)?.getBoundingClientRect().height ?? 0;
      if (labelHeight > 0 && textboxEl.current) textboxEl.current.style.paddingTop = `${labelHeight}px`;
    });
  }, [id]);

  const pinDropButtonClickHandler = useCallback(() => {
    pinDropDispatch(activatePinning(commentId, commentNumber));
    requestAnimationFrame(() => {
      const menu = document.getElementById(`pin-drop-menu-${id}`);
      if (menu) focusFirstElement(menu);
    });
  }, [commentId, commentNumber, id, pinDropDispatch]);

  const validateWordCount = (target: HTMLTextAreaElement) => {
    if (minimumWordCount !== undefined) {
      const wordCount = target.value.trim().split(/\s+/).length;
      target.setCustomValidity(wordCount < minimumWordCount ? `Please enter at least ${minimumWordCount} words.` : '');
    }
  };

  const tableHasEntry = commentTable.hasOwnProperty(commentId) && commentTable[commentId].hasOwnProperty(commentNumber);
  const hasAltText = tableHasEntry && typeof commentTable[commentId][commentNumber].pinDrop?.altText === 'string';
  return (
    <div className="peer-textbox" ref={textboxEl}>
      <textarea
        id={id}
        placeholder={
          placeholder ??
          (required ? 'Please answer the prompt listed above.' : 'You may provide an additional comment.')
        }
        required={required}
        defaultValue={value}
        onChange={(e) => {
          handleChange(e);
          validateWordCount(e.target as HTMLTextAreaElement);
        }}
        onBlur={(e) => {
          handleBlur(e);
          validateWordCount(e.target as HTMLTextAreaElement);
        }}
        onInvalid={(e: React.InvalidEvent<HTMLTextAreaElement>) => {
          validateWordCount(e.target);
          handleInvalid(e);
        }}
      />
      <label id={`${id}-label`} htmlFor={id}>
        <h2 className="peer-textbox-title">{labelText}</h2>
      </label>

      <div
        className="text-box-bottom"
        onClick={(e) => {
          if ((e.target as HTMLElement).classList.contains('text-box-bottom')) document.getElementById(id)?.focus();
        }}
      >
        <span className="word-count">
          {wordCount} word{wordCount != 1 ? 's' : ''}
        </span>

        {review && pinDropContextState.type ? (
          <div className="pin-drop-wrapper">
            {pinDropContextState.commentId === commentId && pinDropContextState.commentNumber === commentNumber ? (
              <div id={`pin-drop-menu-${id}`} className="pin-drop-menu" role="menu">
                {pinDrop !== null ? (
                  <Button
                    tooltip="Delete Pin"
                    className="delete-pin-btn"
                    classOverride
                    type="button"
                    onClick={() => {
                      onPinDelete();
                      pinDropDispatch(deactivatePinning());
                    }}
                  >
                    <Icon code="delete" />
                  </Button>
                ) : null}
                <Button
                  ariaLabel={`Alternative Text${hasAltText ? ' (Complete)' : ''}`}
                  variant={`low xs ${hasAltText ? '' : 'alt'}`}
                  type="button"
                  tooltip={`Add Pin Description${hasAltText ? ' (Complete)' : ''}`}
                  onClick={() =>
                    modalDispatch(
                      openModal({
                        heading: 'Pin Description - Alt Text',
                        closeButton: true,
                        cancelHide: true,
                        buttonText: 'Enter',
                        form: true,
                        maxWidth: '560px',
                        onSubmit: (formData) => {
                          const { altText } = formDataToObjectParsed(formData) as { altText: string };
                          if (tableHasEntry) {
                            let { comment, pinDrop } = commentTable[commentId][commentNumber];
                            if (!pinDrop) pinDrop = { pageNum: -1, xPosition: -1, yPosition: -1, timestamp: -1 };
                            dispatch(
                              updateCommentTable({
                                commentId,
                                commentNumber,
                                comment,
                                pinDrop: { ...pinDrop, altText },
                              }),
                            );
                          }
                        },
                        children: (
                          <>
                            <div>
                              <p aria-hidden>
                                Adding a description of your pinned location{' '}
                                <b>allows peers with visual disabilities to interpret your comments</b> with the help of
                                assistive technologies.
                              </p>
                              <p className="sr-only">
                                For screen reader and keyboard users, you may provide an alternative text description of
                                your comment pin here. Please describe where in the submission document your comment is
                                referencing.
                              </p>
                              <p>
                                <i>We recommend adding a description, although it is not required.</i>
                              </p>
                            </div>
                            <label className="sr-only" htmlFor={`${id}-alt-text`}>
                              Pin Alternative Text
                            </label>
                            <textarea
                              id={`${id}-alt-text`}
                              placeholder="Description"
                              name="altText"
                              required
                              defaultValue={
                                hasAltText
                                  ? (commentTable[commentId][commentNumber].pinDrop as PinData).altText ?? ''
                                  : ''
                              }
                            />
                          </>
                        ),
                      }),
                    )
                  }
                >
                  Alt
                </Button>
                <Button
                  className="cancel-pin"
                  classOverride
                  type="button"
                  onClick={() => pinDropDispatch(deactivatePinning())}
                >
                  {pinDrop !== null ? 'Done' : 'Cancel'}
                </Button>
              </div>
            ) : (
              <>
                {pinDrop !== null ? (
                  <Button
                    tooltip="Edit Pin"
                    className="pin-drop"
                    classOverride
                    type="button"
                    onClick={pinDropButtonClickHandler}
                    ariaHasPopup
                    ariaLabel="Edit Comment Pin"
                  >
                    {renderPinDropLabel(commentId, commentNumber, pinDrop, pinDropContextState)}
                  </Button>
                ) : (
                  <Button
                    tooltip="Pin Comment to Submission"
                    className="unpinned-btn"
                    classOverride
                    type="button"
                    onClick={pinDropButtonClickHandler}
                    ariaHasPopup
                  >
                    <Icon code="push_pin" label="Pin Comment to Submission" />
                  </Button>
                )}
              </>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default TextBox;
