import React, { useState, useEffect, useRef } from 'react';
import { AssignmentWizardPageProps } from '../AssignmentWizard';
import { getDefaultNumberOfReviewers } from '../AssignmentWizardController';
import { Assignment } from '../../../types/types';

export interface Review1Props {
  reviewingCirclesEnabled: boolean;
  reviewsManuallyAssigned: boolean;
  numberOfReviewers: number;
  liveModeReviewAll: boolean;
  allowStudentGroupManagement?: boolean;
  anonymousGroups?: boolean;
}

function ReviewPage1({ assignmentSettings, updateSettings }: AssignmentWizardPageProps<Review1Props>): JSX.Element {
  const initAssignment = useRef<Assignment>(assignmentSettings);

  const [reviewingCirclesEnabled, setReviewingCirclesEnabled] = useState(assignmentSettings.reviewingCirclesEnabled);
  const [reviewsManuallyAssigned, setReviewsManuallyAssigned] = useState(assignmentSettings.reviewsManuallyAssigned);
  const [numberOfReviewers, setNumberOfReviewers] = useState(assignmentSettings.numberOfReviewers);
  const [liveModeReviewAll, setLiveModeReviewAll] = useState(assignmentSettings.liveModeReviewAll);

  useEffect(() => {
    updateSettings({
      reviewingCirclesEnabled,
      reviewsManuallyAssigned,
      numberOfReviewers,
      liveModeReviewAll,
      allowStudentGroupManagement: reviewingCirclesEnabled ? false : undefined,
      anonymousGroups: reviewingCirclesEnabled ? true : undefined,
    });
  }, [updateSettings, reviewingCirclesEnabled, reviewsManuallyAssigned, numberOfReviewers, liveModeReviewAll]);

  useEffect(() => {
    if (reviewsManuallyAssigned) {
      setNumberOfReviewers(-1);
    } else {
      setNumberOfReviewers(
        initAssignment.current.numberOfReviewers !== -1
          ? initAssignment.current.numberOfReviewers
          : getDefaultNumberOfReviewers(initAssignment.current),
      );
    }
  }, [reviewsManuallyAssigned]);

  const suffixes = new Array(10).fill('');
  const recommendedString = ' (Recommended)';
  if (assignmentSettings.groupsEnabled) suffixes[1] = recommendedString;
  else if (assignmentSettings.instructorUpload) suffixes[0] = recommendedString;
  else if (assignmentSettings.liveMode) suffixes[4] = recommendedString;
  else suffixes[2] = recommendedString;

  return (
    <>
      {!assignmentSettings.asyncEnabled ? (
        <fieldset>
          <legend>How will reviews be distributed?</legend>
          <div className="rad-radio-btn">
            <input
              id="rando-dist"
              type="radio"
              name="reviewingCirclesEnabled"
              value="rando-dist"
              checked={!reviewingCirclesEnabled && !reviewsManuallyAssigned}
              onChange={(e) => {
                if (e.target.value === 'rando-dist') {
                  setReviewingCirclesEnabled(false);
                  setReviewsManuallyAssigned(false);
                }
              }}
            />
            <label htmlFor="rando-dist">Randomly</label>
          </div>
          {!assignmentSettings.groupsEnabled && !assignmentSettings.liveMode ? (
            <div className="rad-radio-btn">
              <input
                id="circ-dist"
                type="radio"
                name="reviewingCirclesEnabled"
                value="circ-dist"
                checked={reviewingCirclesEnabled && !reviewsManuallyAssigned}
                onChange={(e) => {
                  if (e.target.value === 'circ-dist') {
                    setReviewingCirclesEnabled(true);
                    setReviewsManuallyAssigned(false);
                  }
                }}
              />
              <label htmlFor="circ-dist">Review Circles</label>
            </div>
          ) : null}
          <div className="rad-radio-btn">
            <input
              id="manual-dist"
              type="radio"
              name="reviewingCirclesEnabled"
              value="manual-dist"
              checked={!reviewingCirclesEnabled && reviewsManuallyAssigned}
              onChange={(e) => {
                if (e.target.value === 'manual-dist') {
                  setReviewingCirclesEnabled(false);
                  setReviewsManuallyAssigned(true);
                }
              }}
            />
            <label htmlFor="manual-dist">Assign Manually</label>
          </div>
          {!reviewingCirclesEnabled && !reviewsManuallyAssigned ? (
            <p>
              Students will review randomly selected submissions from <b>the pool of all submissions</b>.
            </p>
          ) : null}
          {reviewingCirclesEnabled && !reviewsManuallyAssigned ? (
            <p>
              Students will review randomly selected submissions from <b>within their reviewing circle</b>.
            </p>
          ) : null}
          {!reviewingCirclesEnabled && reviewsManuallyAssigned ? (
            <p>
              Students will review submissions <b>assigned by you</b>.
            </p>
          ) : null}
        </fieldset>
      ) : null}

      {!reviewsManuallyAssigned && (
        <>
          <label htmlFor="num-reviews-select">How many reviews per student?</label>
          <select
            name="numberOfReviews"
            id="num-reviews-select"
            required
            value={numberOfReviewers}
            onChange={(e) => {
              const value = parseInt(e.target.value);
              setNumberOfReviewers(value);
              if (value === 500) {
                // Set liveModeReviewAll to true if "All de-anonymized reviews" is chosen.
                setLiveModeReviewAll(true);
              }
            }}
          >
            {[1, 2, 3, 4].map((num, idx) => (
              <option key={num} value={num}>
                {num}
                {suffixes[idx]}
              </option>
            ))}
            {assignmentSettings.asyncEnabled &&
              Array.from({ length: 6 }, (_, idx) => idx + 5).map((num) => (
                <option key={num} value={num}>
                  {num}
                </option>
              ))}
            {assignmentSettings.liveMode && <option value={500}> All de-anonymized reviews {suffixes[4]}</option>}
            {reviewingCirclesEnabled && <option value={500}> All review circle submissions {suffixes[4]}</option>}
          </select>
        </>
      )}

      {assignmentSettings.instructorUpload ? (
        <p>This also indicates the minimum number of submissions required by the instructor(s).</p>
      ) : null}
    </>
  );
}

export default ReviewPage1;
