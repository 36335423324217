import React, { useState, useRef, useMemo } from 'react';
import _ from 'lodash';
import FilterTab from '../core/layout/FilterTab/FilterTab';
import { CommentWithReviewComments, ReviewCommentWithName } from '../../types/types';
import Button from '../core/button/Button/Button';
import { useParams } from 'react-router-dom';

interface Props {
  peerComments: CommentWithReviewComments[];
  reviewCommentWithName?: ReviewCommentWithName[];
  showDialogueButton?: boolean;
  deanonymizeReviewers?: boolean;
}

function PeerCommentsCard({
  peerComments,
  reviewCommentWithName,
  showDialogueButton = false,
  deanonymizeReviewers,
}: Props): JSX.Element {
  const { courseId, assignmentId } = useParams() as { courseId: string; assignmentId: string };
  const commentNames = useMemo(() => {
    if (reviewCommentWithName) {
      return [...new Set(reviewCommentWithName.map((reviewComment) => reviewComment.commentName))];
    } else {
      return [...new Set(peerComments.map((peerComment) => peerComment.comment.commentName))];
    }
  }, [peerComments, reviewCommentWithName]);

  const [filterList, setFilterList] = useState<string[]>([]);
  const commentsWrapperEl = useRef<HTMLDivElement>(null);

  const dataExists = reviewCommentWithName ? !_.isEmpty(reviewCommentWithName) : !_.isEmpty(peerComments);
  const emptyClass = !dataExists ? ' empty' : '';

  return (
    <div className={`panel-sm panel-white peer-comments-card results-card-long${emptyClass}`}>
      <h2 className="title">Peer Comments</h2>
      {dataExists ? (
        <>
          <FilterTab setFilterList={setFilterList}>
            {commentNames
              ? commentNames.map((name) => (
                  <FilterTab.Button key={`btn-${name}`} id={`btn-${name}`} type="checkbox">
                    {name}
                  </FilterTab.Button>
                ))
              : ''}
          </FilterTab>
          <div ref={commentsWrapperEl} className="comments-wrapper">
            {reviewCommentWithName
              ? reviewCommentWithName
                  .filter((reviewComment) =>
                    filterList.length > 0 ? filterList.includes(reviewComment.commentName) : true,
                  )
                  .map((reviewComment) => (
                    <React.Fragment key={reviewComment.commentName}>
                      <Comment
                        key={`comment-${reviewComment.reviewComment.reviewCommentId}`}
                        name={reviewComment.commentName}
                        comment={reviewComment.reviewComment.comment}
                        reviewerName={reviewComment.reviewComment.commenterName}
                        deanonymizeReviewers={deanonymizeReviewers}
                      />
                    </React.Fragment>
                  ))
              : peerComments
                  .filter((peerComment) =>
                    filterList.length > 0 ? filterList.includes(peerComment.comment.commentName) : true,
                  )
                  .map((peerComment) => (
                    <React.Fragment key={peerComment.comment.commentName}>
                      {Object.keys(peerComment.reviewCommentsMap).map((reviewCommentId) => {
                        const reviewComments = peerComment.reviewCommentsMap[reviewCommentId];
                        return reviewComments.map((reviewComment) => (
                          <Comment
                            key={`comment-${reviewComment.reviewCommentId}`}
                            name={peerComment.comment.commentName}
                            comment={reviewComment.comment}
                            reviewerName={reviewComment.commenterName}
                            deanonymizeReviewers={deanonymizeReviewers}
                          />
                        ));
                      })}
                    </React.Fragment>
                  ))}
            <div
              className="back-to-top"
              onClick={() => {
                if (commentsWrapperEl.current) commentsWrapperEl.current.scrollTop = 0;
              }}
            >
              <p>Back to top</p>
            </div>
            {showDialogueButton ? (
              <Button
                id="see-dialogue-btn"
                className="peer-btn-low"
                classOverride
                href={`/course/${courseId}/assignment/${assignmentId}/results/dialogue`}
                route
              >
                See Full Dialogue
              </Button>
            ) : null}
          </div>
        </>
      ) : (
        <p style={{ textAlign: 'center' }}>
          <b>Comments Unavailable</b> <br /> User did not submit
        </p>
      )}
    </div>
  );
}

interface CommentProps {
  comment: string;
  name: string;
  reviewerName?: string;
  deanonymizeReviewers?: boolean;
}

function Comment({ comment, name, reviewerName, deanonymizeReviewers }: CommentProps): JSX.Element {
  return (
    <blockquote className="peer-comment">
      <div className="name-label">
        <i>
          {deanonymizeReviewers && reviewerName ? reviewerName : ''} on <b>{name}</b>
        </i>
      </div>
      <div className="comment-label">{comment}</div>
    </blockquote>
  );
}

export default PeerCommentsCard;
